.testimonial {
  &-hero {
    padding: 4rem 1rem;
    margin: 4rem 0;

    .title {
      font-weight: 700;
      font-size: 4.5rem;
      line-height: 1.2;
      text-align: center;

      color: #000;

      @include sm {
        font-size: 3rem;
      }
    }

    p {
      color: #666;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      text-align: center;
    }
  }

  &-list {
    padding: 5rem 2rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    column-gap: 2rem;
    row-gap: 2rem;
    overflow-x: hidden;

    @include sm {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }
  }

  &-box {
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 10px;
    border: 1px solid #eaeaea;

    img {
      width: 128px;
      height: 128px;
      object-fit: cover;
      border-radius: 50%;
      margin: 0 1.5rem;
    }

    a {
      padding: 0.5rem 1.2rem;
      margin-top: 1rem;
    }
  }

  &-header {
    display: flex;
    justify-content: center;
    padding: 1rem;

    &-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .testimonial-name {
      font-size: 1.3rem;
      font-weight: 700;
      color: #000;
    }

    .testimonial-biotext {
      font-size: 1rem;
      color: #666;
    }
  }

  &-desc {
    padding: 1.5rem 1rem;
    color: #333;
  }
}
