h1 {
  margin-top: 3%;
  margin-bottom: -1%;
}

.projects {
  &-hero {
    padding: 4rem 1rem 1rem 1rem;
    margin: 4rem 0 1rem 0;

    .title {
      font-weight: 700;
      font-size: 4.5rem;
      line-height: 1.2;
      text-align: center;

      color: #000;

      @include sm {
        font-size: 3rem;
      }
    }

    p {
      color: #666;
      font-size: 1.2rem;
      font-weight: 400;
      line-height: 2rem;
      text-align: center;
    }
  }

  &-search-box {
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 4rem;
      margin-bottom: 1.5rem;
      font-family: $header-font;
      font-weight: 700;
    }

    .input {
      width: 80vw;
      padding: 1rem 1.4rem;
      border-radius: 5px;
      outline: none;
      // box-shadow: 0.2rem 0.2rem 0.4rem rgba($color: #1a1a1a, $alpha: 0.1);
      border: 1px solid #eaeaea;
      color: $text-color-black;
      font: inherit;

      @include lg {
        width: 50rem;
      }
    }
  }
}

.project-card-grid {
  padding: 5rem 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 3rem;
  row-gap: 3rem;
  overflow-x: hidden;

  @include sm {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
