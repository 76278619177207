.menu {
  color: #666;
  font-size: 0.875rem;
  background-color: transparent;
  z-index: 420;

  position: sticky;
  top: 0;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(#fff, 0.8);
  transition: background-color 0.1s ease-in;

  &:hover {
    background-color: rgba(#fff, 1);
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;

    padding: 1rem;
    margin: 0 auto;
    max-width: 1000px;

    ul {
      display: flex;
      justify-content: center;
      list-style: none;

      li {
        display: flex;
        align-items: center;
        white-space: nowrap;

        margin: 0 0.5rem;
        padding: 0.5rem 0.75rem;
        cursor: pointer;

        transition: background-color 0.1s ease-in, color 0.1s ease-in;

        svg {
          height: 20px;
          width: 20px;

          path {
            stroke-width: 1px;
          }
        }

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #666;
          position: relative;
        }

        &:hover {
          color: #000;
          background-color: #eee;
          border-radius: 5px;

          a {
            color: #000;
          }
        }

        ul {
          display: none;
          position: absolute;
          top: 96px;
          left: 0;

          padding: 2rem;
          white-space: nowrap;
          width: 100%;

          background-color: #fff;
          opacity: 1;
          border-radius: 6px;
          box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25),
            0 30px 60px -30px rgba(0, 0, 0, 0.3);

          box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);

          li {
            border: 1px solid transparent;
            padding: 1rem;
            font-weight: 500;

            p {
              font-weight: 400;
            }

            .icon {
              display: flex;

              border-radius: 50%;
              background-color: #000;
              padding: 1rem;
              margin: 0 0.5rem;

              svg {
                stroke-width: 2px;
                path {
                  color: #fff;
                }
              }
            }

            &:hover {
              border: 1px solid transparent;
            }
          }
        }

        &.isDown {
          & > ul {
            display: flex;
          }
        }
      }
    }
  }

  @include sm {
    display: none;
  }
  @include md {
    display: none;
  }
}

.mobile-navbar {
  position: sticky;
  top: 0;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(#fff, 0.8);
  z-index: 420;

  .wrapper {
    visibility: hidden;
    overflow: hidden;
    max-height: 0;
    width: 100%;

    display: flex;
    opacity: 0;

    ul {
      display: flex;
      flex-direction: column;

      list-style: none;
      padding: 0;
      margin: 6rem 1rem;
      width: 100vw;

      font-size: 0.875rem;

      li {
        padding: 0.5rem;
        cursor: pointer;

        width: 100%;
        border-bottom: 1px solid #eaeaea;

        a {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #000;
        }

        img {
          width: 40px;
        }
      }
    }
  }

  .dropdown-title {
    cursor: pointer;
    display: flex;
    align-items: center;

    color: #000;
    .dropdown-content {
      display: none;
    }
  }

  .down {
    .dropdown-content {
      display: block;
      margin: 0.5rem 0 0 0;

      white-space: nowrap;
    }
  }

  .active {
    visibility: visible;
    max-height: 100%;
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 1;

    @include mobile-navbar-items();
    transition: opacity 0.35s, visibility 0.35s, height 0.35s;
    background: #fff;
  }

  .bar {
    display: flex;
    justify-content: space-between;
    width: 98vw;

    .logo {
      margin-left: 1rem;
      margin-top: 0.5rem;
      display: none;
      a {
        display: flex;
        text-decoration: none;
        color: #000;
      }

      img {
        height: 40px;
        cursor: pointer;
      }

      @include sm {
        display: none;
      }
      @include md {
        display: none;
      }
    }
  }

  @include mobile-navbar-icon();

  .mobile-navbar-icon {
    display: none;
    position: relative;
    z-index: 99;
    width: 30px;
    height: 50px;

    cursor: pointer;

    span {
      position: absolute;
      width: 22px;
      height: 1px;
      background-color: #000;
    }

    @include sm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    @include md {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .out {
    span {
      background-color: #000;
    }
  }
}
