@mixin mobile-navbar-items {
  @keyframes fadeInRight {
    0% {
      opacity: 0;
      transform: translateX(20px);
    }

    100% {
      opacity: 1;
      transform: traslateX(0px);
    }
  }

  li {
    animation: fadeInRight 0.5s ease forwards;
    animation-delay: 0.25s;

    @for $i from 2 through 10 {
      &:nth-child(#{$i}n) {
        animation-delay: #{0.25 + $i * 0.05}s;
      }
    }
  }
}

@mixin mobile-navbar-icon {
  @keyframes clickfirst {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(45deg);
    }
  }

  @keyframes clicklast {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-45deg);
    }
  }

  @keyframes outfirst {
    0% {
      transform: rotate(45deg);
    }

    100% {
      transform: translateY(4px) rotate(0deg);
    }
  }

  @keyframes outlast {
    0% {
      transform: rotate(-45deg);
    }

    100% {
      transform: translateY(-4px) rotate(0deg);
    }
  }

  .nope {
    span {
      &:nth-child(1) {
        animation: outfirst 0.2s;
        animation-fill-mode: both;
      }

      &:nth-child(2) {
        animation: outlast 0.2s;
        animation-fill-mode: both;
      }
    }
  }

  .out {
    span {
      &:nth-child(1) {
        animation: clickfirst 0.2s;
        animation-fill-mode: both;
      }

      &:nth-child(2) {
        animation: clicklast 0.2s;
        animation-fill-mode: both;
      }
    }
  }
}
