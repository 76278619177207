.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 700;
    font-size: 4.5rem;
    line-height: 1.2;
    text-align: center;

    color: #000;

    @include sm {
      font-size: 3rem;
    }
  }

  p {
    color: #666;
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 2rem;
    text-align: center;
  }
}
