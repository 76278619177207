//@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");

.timeline {
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  margin: 0;

  & .timeline-container {
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 4rem 0;

    &::after {
      background-color: #ccc;
      content: "";
      position: absolute;
      left: 50%;
      width: 2px;
      transform: translateX(-100%);
      height: 100%;
    }
    & .timeline-item {
      display: flex;
      justify-content: flex-end;
      padding-right: 3rem;
      margin: 1rem 0;
      width: 50%;

      &:nth-child(odd) {
        align-self: flex-end;
        justify-content: flex-start;
        padding-right: 0px;
        padding-left: 2rem;

        & .timeline-item-content {
          align-items: center;
          text-align: center;

          &::after {
            right: auto;
            left: -7.5px;
          }
          & .tag {
            left: auto;
            right: 5px;
          }
          & .circle {
            right: auto;
            left: -2.6rem;
          }
        }
      }
      & .timeline-item-content {
        border-radius: 20px;
        width: 80vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem;
        max-width: 100%;
        text-align: center;
        position: relative;

        & .tag {
          color: white;
          font-size: 1.2rem;
          font-weight: bold;
          padding: 5px;
          position: absolute;
          top: 5px;
          left: 5px;
        }
        & .time {
          font-weight: 700;
        }
        & p {
          font-size: 1.2rem;
          margin: 1rem 0;
          line-height: 1.5rem;
          max-width: 20rem;
          font-weight: 500;
        }
        & a {
          color: #b57aff;
          text-decoration: none;
          font-size: 14px;
          &:after {
            content: "\2192";
          }
        }
        & .circle {
          background-color: #fff;
          border: 3px solid #444;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          right: -3.4rem;
          z-index: 20;
          position: absolute;
          top: 50%;
        }
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .timeline-item-content,
  .timeline-item:nth-child(odd).timeline-item-content {
    text-align: center;
    align-items: center;
    width: 380px;
  }
}
