.table-container {
  display: flex;
  justify-content: center;

  table {
    border-radius: 10px;
    border-collapse: collapse;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    max-height: 100vh;
    overflow-y: scroll;

    thead {
      background-color: #b4e4c9;
      color: #111;

      tr {
        th {
          padding: 0.5rem;
        }
      }
    }

    tbody {
      text-align: center;
      tr {
        td {
          padding: 0.5rem;
        }

        &:nth-of-type(even) {
          background-color: #efefef;
        }
      }
    }
  }
}
