.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.75) 44.42%,
      #000000 64.48%
    ),
    url(/dashboard-bg.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #000;
  color: #fff;
  padding: 1rem;

  .dashboard {
    a {
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        color: rgba(255, 255, 255, 1);
      }
    }

    &-header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin: 2rem 0;

      h1 {
        font-size: 4rem;
        margin: 0;
      }

      h2 {
        color: rgba(255, 255, 255, 0.7);
        margin: 0 0 0.5rem 0;
      }

      .stats {
        display: flex;
        flex-direction: row;

        .box {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          margin: 0.5rem;
          padding: 1rem 2.5rem;
          text-align: center;

          h2 {
            font-size: 4rem;
            margin: 0.5rem 0;
            color: #fff;
          }

          p {
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }

      .avatar {
        margin: 0 1rem;
        img {
          border-radius: 10px;
          max-height: 20rem;
        }

        &-content {
          text-align: center;
          color: rgba(255, 255, 255, 0.7);

          h2 {
            margin: 0.5rem 0;
          }
        }

        @include sm {
          display: none;
        }
      }

      @include sm {
        flex-direction: column;

        .stats {
          flex-direction: column;

          .box {
            width: 100%;
          }
        }
      }
    }

    .subtitle {
      flex-grow: 1;
      h1 {
        font-size: 2.5rem;
        margin: 2rem 0 1rem 0;
      }

      .languages {
        span {
          background: rgba(255, 255, 255, 0.1);
          margin: 0 1rem 0 0;
          padding: 0.5rem 0.8rem;
          border-radius: 10%;
        }

        @include sm {
          span {
            border-radius: 2%;
            margin: 1rem;
            display: block;
          }
        }
      }
    }
    .feedback {
      font-size: 1.5rem;

      p {
        color: rgba(255, 255, 255, 0.7);
        max-width: 50vw;

        @include sm {
          max-width: 100%;
        }
      }

      a {
        color: rgb(86, 131, 255);
      }
    }

    &-table {
      max-width: 50vw;
      thead {
        th {
          font-size: 1.5rem;
        }
      }

      tbody {
        a {
          margin: 0.5rem;
        }
      }

      @include sm {
        display: none;
      }
    }

    &-table-message {
      display: none;

      @include sm {
        display: block;
      }
    }

    .resource-table {
      table {
        tbody {
          td {
            margin: 0 1rem 0 0;
            font-size: 1.2rem;

            img {
              height: 100px;
              border-radius: 10px;

              @include sm {
                height: 70px;
              }
            }
          }
        }
      }
    }

    .project-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: 0 1rem 0 0;
      max-width: 50vw;

      @include sm {
        max-width: 100%;
      }

      .card-container {
        display: flex;
        flex-direction: column;
        justify-content: center;

        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;

        margin: 1rem;
        padding: 1rem;

        max-width: 350px;
        max-height: 200px;

        @include sm {
          width: 100%;
        }

        p,
        h1 {
          padding: 0px;
          margin: 0px;
        }

        .info {
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            height: 40px;
            width: 40px;
            margin: 0.5rem;

            border-radius: 2px;
          }

          p {
            font-size: 1.5rem;
          }
        }

        .buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          a {
            margin: 0.5rem;
          }
        }
      }
    }
  }

  .students {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    // TODO: better way to center everything and limit width
    max-width: 50vw;
    @include sm {
      max-width: 100%;
    }

    .student {
      margin: 1rem;

      .header {
        img {
          height: 8rem;
          border-radius: 10px;
        }
      }
    }
  }

  .add-project-link {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;

    margin: 1rem;
    padding: 1rem;

    width: fit-content;

    max-width: 350px;
    max-height: 200px;

    .plus-sign {
      font-size: 3rem;
      margin: 0;
    }
  }

  .pull-reqs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    max-width: 50vw;
    @include sm {
      max-width: 100%;
    }

    .pull-req {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 10px;
      margin: 0.2rem;
      padding: 0.5rem;

      @include sm {
        font-size: 3vw;
      }

      .link {
        display: inline;
        margin-right: 0.2rem;
        margin-left: 0.5rem;

        a {
          color: rgb(86, 131, 255);
        }
      }
    }
  }
}
