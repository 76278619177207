.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  color: $text-color-black;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-header {
    font-size: 2rem;
    font-family: $header-font;
    padding: 1rem 0;
    font-weight: 700;
  }

  .details {
    font-size: 1rem;
    color: #333;

    b,
    div {
      display: inline-block;
      padding-right: 0.5rem;
    }
  }

  &-content {
    .content {
      font-size: 1rem;
      color: #666;
      margin: 1rem 0;
    }

    .tag {
      display: inline-block;
      text-decoration: none;
      background-color: #fafafa;
      border: 1px solid #eaeaea;
      font-size: 1rem;
      color: #333;
      margin: 0.5rem;
      padding: 0.5rem 0.8rem;
      border-radius: 5px;
      text-align: center;
    }
  }

  &-footer {
    width: 100%;
    margin-top: auto;
    padding-top: 1rem;
    display: flex;
    justify-content: space-evenly;

    a {
      padding: 0.6rem 1rem;
      border-radius: 5px;
    }
  }
}
