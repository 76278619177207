button,
.button {
  background-color: $primary-background-black;
  text-decoration: none;
  color: #fff;

  border: 1px solid #111;
  border-radius: 5px;
  margin: 1rem 0.5rem;
  padding: 1rem 1.5rem;

  transition: all 0.2s ease-in-out;

  &.secondary {
    color: #111;
    background-color: #fff;
    border: 1px solid #eaeaea;

    &:hover {
      border: 1px solid #111;
      background-color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid #111;
    background-color: transparent;
    color: #111;
  }
}

.input,
input,
.textarea,
textarea {
  font-family: inherit;
  font-size: inherit;
  outline: none;

  border: 2px solid #111;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;

  &:focus {
    border: 2px solid #0070f3;
  }
}

a {
  text-decoration: none;
  color: #0a72ef;

  transition: color 0.2s ease-in;

  &:hover {
    color: #000;
  }
}

.multicolor {
  &[color="red"] {
    background: linear-gradient(90deg, rgba(#ff4d4d, 0.8), rgba(#f9cb28, 0.8));
    color: #fff;
  }
  &[color="green"] {
    background: linear-gradient(90deg, rgba(#7928ca, 0.6), rgba(#ff0080, 0.8));
    color: #fff;
  }
  &[color="blue"] {
    background: linear-gradient(90deg, rgba(#007cf0, 0.8), rgba(#00dfd8, 0.8));
    color: #fff;
  }
  &[color="grey"] {
    background: linear-gradient(90deg, rgba(#50e3c2, 0.8), rgba(#7928ca, 0.8));
    color: #fff;
  }
}
