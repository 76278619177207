@keyframes spainWithoutA {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(40deg) translateX(-20%);
  }
  25% {
    transform: translateY(-50%) translateX(-50%) skew(15deg, 15deg)
      rotate(110deg) translateX(-5%);
  }
  50% {
    transform: translateY(-50%) translateX(-50%) rotate(210deg) translateX(-35%);
  }
  75% {
    transform: translateY(-50%) translateX(-50%) skew(-15deg, -15deg)
      rotate(300deg) translateX(-10%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(400deg) translateX(-20%);
  }
}

.blurred-circles {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;

  .wrapper {
    filter: blur(80px);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div {
      position: absolute;
      border-radius: 100%;

      &:nth-of-type(1) {
        z-index: -1;
        background-color: #50e3c2;
        opacity: 0.5;
        top: 60%;
        left: 40%;

        width: 700px;
        height: 700px;

        animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite
          alternate;
      }

      &:nth-of-type(2) {
        z-index: -2;
        background-color: #7928ca;
        opacity: 0.45;
        top: 50%;
        left: 50%;

        width: 600px;
        height: 600px;
        animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite both;
      }

      &:nth-of-type(3) {
        z-index: -3;
        background-color: #50e3c2;
        opacity: 0.3;

        top: 40%;
        left: 60%;

        width: 500px;
        height: 500px;
        animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite
          reverse;
      }
    }
  }
}
