.footer {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: #fafafa;
  padding: 1rem;
  border-top: 1px solid #eaeaea;

  nav {
    width: 50rem;
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    font-size: 0.875rem;

    .footer-column {
      margin: 0 1rem;
      h3 {
        font-weight: 400;
        font-size: 0.875rem;
      }

      ul {
        list-style-type: none;
        padding: 0;
        color: #666;
        margin-top: 1.2rem;

        li {
          padding-bottom: 0.8rem;

          a {
            display: block;
            color: inherit;
            text-decoration: none;

            &:hover {
              color: #b67dfc;
            }
          }
        }
      }
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0;

    h1 {
      font-size: 1.7rem;
    }

    h2 {
      font-size: 1.2rem;
    }
  }

  @include sm {
    nav {
      flex-direction: column;
      text-align: center;
      width: 80vw;
    }
  }

  @include md {
    nav {
      width: 80vw;
      justify-content: space-evenly;
    }
  }
}
