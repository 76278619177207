.particles {
  width: 100%;
  height: 100%;
  background: url("../assets/intro-background.jpg");
  background-size: cover;

  &:before {
    content: "";
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}

.home {
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;

  .hero {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: cover;
    height: 90vh;
    margin: 0;

    .hero-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 50%;
      padding: 1rem;
      text-align: center;

      @include sm {
        width: 100%;
      }

      .title {
        color: $primary-text-black;
        margin-top: 2rem;
        font-family: $header-font;
        letter-spacing: 2px;
        font-size: 8rem;
        font-weight: 800;

        @include sm {
          font-size: 5.5rem;
        }
      }

      .subtitle {
        display: flex;
        align-items: center;

        font-family: $header-font;
        font-size: 2.5rem;
        margin: 0.75rem;
        font-weight: 600;
        color: #444;

        a {
          display: flex;
        }

        img {
          width: 10rem;
          margin-left: 1rem;
        }
      }

      .btns-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include sm {
          flex-direction: column;
        }
      }
    }
  }

  .container {
    h1 {
      font-size: 4rem;
      font-family: $header-font;
      letter-spacing: 2px;
      align-items: center;
    }
  }

  .about {
    color: $text-color-black;
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5rem 20% 0 20%;
    p {
      font-size: 1.3rem;
    }
    // TODO: use rem for font size
  }

  .why-kwoc {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      text-align: center;
    }

    &-cardlist {
      display: flex;
      justify-content: space-evenly;
      width: 70vw;
      margin: 2rem 0;

      @include sm {
        flex-direction: column;
        width: 80vw;
      }
    }

    &-card {
      color: #111;
      margin: 1rem;
      padding: 1rem 1.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;

      h2 {
        font-size: 1.5rem;
      }
    }
  }

  @media (max-width: 600px) {
    .why-kwoc .container {
      padding: 30px 10% 5rem;
    }
  }

  .tags {
    text-align: center;
    min-height: 50vh;
    // background-color: $primary-theme;
    margin-top: 5rem;

    &-title {
      margin-bottom: 2rem;
    }

    &-list {
      margin: 2rem 0;
    }

    .tag {
      display: inline-block;
      text-decoration: none;
      padding: 0.8rem 1.2rem;
      margin: 1rem;
      border-radius: 5px;
      cursor: pointer;
      font-weight: 600;
    }
  }

  .previous-year {
    min-height: 80vh;
    background-size: cover;
    margin: 0 0 5rem 0;
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 4rem;
      text-align: center;
    }

    &-column-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 4rem;

      @include sm {
        flex-direction: column;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 4rem;
      padding: 1.5rem;
      border-radius: 20px;
      width: 20rem;
    }
  }
  @media (max-width: 768px) {
    .stats .container .columns .column {
      padding: 2rem 0;
    }
  }
}

// TODO: replace these with the mixins
@media (max-width: 400px) {
  .hero {
    .manual-btns {
      flex-direction: column;
      padding: 0 20%;
      .control:not(:last-child) {
        margin-right: 0 !important;
      }
    }
  }
}
