@charset "UTF-8";
button,
.button {
  background-color: #000000;
  text-decoration: none;
  color: #fff;
  border: 1px solid #111;
  border-radius: 5px;
  margin: 1rem 0.5rem;
  padding: 1rem 1.5rem;
  transition: all 0.2s ease-in-out;
}
button.secondary,
.button.secondary {
  color: #111;
  background-color: #fff;
  border: 1px solid #eaeaea;
}
button.secondary:hover,
.button.secondary:hover {
  border: 1px solid #111;
  background-color: #fff;
}
button:hover,
.button:hover {
  cursor: pointer;
  border: 1px solid #111;
  background-color: transparent;
  color: #111;
}

.input,
input,
.textarea,
textarea {
  font-family: inherit;
  font-size: inherit;
  outline: none;
  border: 2px solid #111;
  border-radius: 5px;
  margin: 1rem 0;
  padding: 1rem;
}
.input:focus,
input:focus,
.textarea:focus,
textarea:focus {
  border: 2px solid #0070f3;
}

a {
  text-decoration: none;
  color: #0a72ef;
  transition: color 0.2s ease-in;
}
a:hover {
  color: #000;
}

.multicolor[color=red] {
  background: linear-gradient(90deg, rgba(255, 77, 77, 0.8), rgba(249, 203, 40, 0.8));
  color: #fff;
}
.multicolor[color=green] {
  background: linear-gradient(90deg, rgba(121, 40, 202, 0.6), rgba(255, 0, 128, 0.8));
  color: #fff;
}
.multicolor[color=blue] {
  background: linear-gradient(90deg, rgba(0, 124, 240, 0.8), rgba(0, 223, 216, 0.8));
  color: #fff;
}
.multicolor[color=grey] {
  background: linear-gradient(90deg, rgba(80, 227, 194, 0.8), rgba(121, 40, 202, 0.8));
  color: #fff;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  padding: 1rem;
  border-top: 1px solid #eaeaea;
}
.footer nav {
  width: 50rem;
  display: flex;
  justify-content: space-between;
  margin: 2rem 0;
  font-size: 0.875rem;
}
.footer nav .footer-column {
  margin: 0 1rem;
}
.footer nav .footer-column h3 {
  font-weight: 400;
  font-size: 0.875rem;
}
.footer nav .footer-column ul {
  list-style-type: none;
  padding: 0;
  color: #666;
  margin-top: 1.2rem;
}
.footer nav .footer-column ul li {
  padding-bottom: 0.8rem;
}
.footer nav .footer-column ul li a {
  display: block;
  color: inherit;
  text-decoration: none;
}
.footer nav .footer-column ul li a:hover {
  color: #b67dfc;
}
.footer-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
.footer-title h1 {
  font-size: 1.7rem;
}
.footer-title h2 {
  font-size: 1.2rem;
}
@media (max-width: 576px) {
  .footer nav {
    flex-direction: column;
    text-align: center;
    width: 80vw;
  }
}
@media (min-width: 577px) and (max-width: 1449px) {
  .footer nav {
    width: 80vw;
    justify-content: space-evenly;
  }
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 1rem 1.5rem;
  color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card-header {
  font-size: 2rem;
  font-family: "Inter", sans-serif;
  padding: 1rem 0;
  font-weight: 700;
}
.card .details {
  font-size: 1rem;
  color: #333;
}
.card .details b,
.card .details div {
  display: inline-block;
  padding-right: 0.5rem;
}
.card-content .content {
  font-size: 1rem;
  color: #666;
  margin: 1rem 0;
}
.card-content .tag {
  display: inline-block;
  text-decoration: none;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  font-size: 1rem;
  color: #333;
  margin: 0.5rem;
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
  text-align: center;
}
.card-footer {
  width: 100%;
  margin-top: auto;
  padding-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}
.card-footer a {
  padding: 0.6rem 1rem;
  border-radius: 5px;
}

.menu {
  color: #666;
  font-size: 0.875rem;
  background-color: transparent;
  z-index: 420;
  position: sticky;
  top: 0;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8);
  transition: background-color 0.1s ease-in;
}
.menu:hover {
  background-color: white;
}
.menu .wrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1000px;
}
.menu .wrapper ul {
  display: flex;
  justify-content: center;
  list-style: none;
}
.menu .wrapper ul li {
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0 0.5rem;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  transition: background-color 0.1s ease-in, color 0.1s ease-in;
}
.menu .wrapper ul li svg {
  height: 20px;
  width: 20px;
}
.menu .wrapper ul li svg path {
  stroke-width: 1px;
}
.menu .wrapper ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #666;
  position: relative;
}
.menu .wrapper ul li:hover {
  color: #000;
  background-color: #eee;
  border-radius: 5px;
}
.menu .wrapper ul li:hover a {
  color: #000;
}
.menu .wrapper ul li ul {
  display: none;
  position: absolute;
  top: 96px;
  left: 0;
  padding: 2rem;
  white-space: nowrap;
  width: 100%;
  background-color: #fff;
  opacity: 1;
  border-radius: 6px;
  box-shadow: 0 50px 100px -20px rgba(50, 50, 93, 0.25), 0 30px 60px -30px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.02);
}
.menu .wrapper ul li ul li {
  border: 1px solid transparent;
  padding: 1rem;
  font-weight: 500;
}
.menu .wrapper ul li ul li p {
  font-weight: 400;
}
.menu .wrapper ul li ul li .icon {
  display: flex;
  border-radius: 50%;
  background-color: #000;
  padding: 1rem;
  margin: 0 0.5rem;
}
.menu .wrapper ul li ul li .icon svg {
  stroke-width: 2px;
}
.menu .wrapper ul li ul li .icon svg path {
  color: #fff;
}
.menu .wrapper ul li ul li:hover {
  border: 1px solid transparent;
}
.menu .wrapper ul li.isDown > ul {
  display: flex;
}
@media (max-width: 576px) {
  .menu {
    display: none;
  }
}
@media (min-width: 577px) and (max-width: 1449px) {
  .menu {
    display: none;
  }
}

.mobile-navbar {
  position: sticky;
  top: 0;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 420;
}
.mobile-navbar .wrapper {
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  width: 100%;
  display: flex;
  opacity: 0;
}
.mobile-navbar .wrapper ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 6rem 1rem;
  width: 100vw;
  font-size: 0.875rem;
}
.mobile-navbar .wrapper ul li {
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}
.mobile-navbar .wrapper ul li a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}
.mobile-navbar .wrapper ul li img {
  width: 40px;
}
.mobile-navbar .dropdown-title {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000;
}
.mobile-navbar .dropdown-title .dropdown-content {
  display: none;
}
.mobile-navbar .down .dropdown-content {
  display: block;
  margin: 0.5rem 0 0 0;
  white-space: nowrap;
}
.mobile-navbar .active {
  visibility: visible;
  max-height: 100%;
  position: fixed;
  z-index: 98;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 1;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  background: #fff;
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: traslateX(0px);
  }
}
.mobile-navbar .active li {
  animation: fadeInRight 0.5s ease forwards;
  animation-delay: 0.25s;
}
.mobile-navbar .active li:nth-child(2n) {
  animation-delay: 0.35s;
}
.mobile-navbar .active li:nth-child(3n) {
  animation-delay: 0.4s;
}
.mobile-navbar .active li:nth-child(4n) {
  animation-delay: 0.45s;
}
.mobile-navbar .active li:nth-child(5n) {
  animation-delay: 0.5s;
}
.mobile-navbar .active li:nth-child(6n) {
  animation-delay: 0.55s;
}
.mobile-navbar .active li:nth-child(7n) {
  animation-delay: 0.6s;
}
.mobile-navbar .active li:nth-child(8n) {
  animation-delay: 0.65s;
}
.mobile-navbar .active li:nth-child(9n) {
  animation-delay: 0.7s;
}
.mobile-navbar .active li:nth-child(10n) {
  animation-delay: 0.75s;
}
.mobile-navbar .bar {
  display: flex;
  justify-content: space-between;
  width: 98vw;
}
.mobile-navbar .bar .logo {
  margin-left: 1rem;
  margin-top: 0.5rem;
  display: none;
}
.mobile-navbar .bar .logo a {
  display: flex;
  text-decoration: none;
  color: #000;
}
.mobile-navbar .bar .logo img {
  height: 40px;
  cursor: pointer;
}
@media (max-width: 576px) {
  .mobile-navbar .bar .logo {
    display: none;
  }
}
@media (min-width: 577px) and (max-width: 1449px) {
  .mobile-navbar .bar .logo {
    display: none;
  }
}
@keyframes clickfirst {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
  }
}
@keyframes clicklast {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
  }
}
@keyframes outfirst {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: translateY(4px) rotate(0deg);
  }
}
@keyframes outlast {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: translateY(-4px) rotate(0deg);
  }
}
.mobile-navbar .nope span:nth-child(1) {
  animation: outfirst 0.2s;
  animation-fill-mode: both;
}
.mobile-navbar .nope span:nth-child(2) {
  animation: outlast 0.2s;
  animation-fill-mode: both;
}
.mobile-navbar .out span:nth-child(1) {
  animation: clickfirst 0.2s;
  animation-fill-mode: both;
}
.mobile-navbar .out span:nth-child(2) {
  animation: clicklast 0.2s;
  animation-fill-mode: both;
}
.mobile-navbar .mobile-navbar-icon {
  display: none;
  position: relative;
  z-index: 99;
  width: 30px;
  height: 50px;
  cursor: pointer;
}
.mobile-navbar .mobile-navbar-icon span {
  position: absolute;
  width: 22px;
  height: 1px;
  background-color: #000;
}
@media (max-width: 576px) {
  .mobile-navbar .mobile-navbar-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 577px) and (max-width: 1449px) {
  .mobile-navbar .mobile-navbar-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.mobile-navbar .out span {
  background-color: #000;
}

.timeline {
  padding: 5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  margin: 0;
}
.timeline .timeline-container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 4rem 0;
}
.timeline .timeline-container::after {
  background-color: #ccc;
  content: "";
  position: absolute;
  left: 50%;
  width: 2px;
  transform: translateX(-100%);
  height: 100%;
}
.timeline .timeline-container .timeline-item {
  display: flex;
  justify-content: flex-end;
  padding-right: 3rem;
  margin: 1rem 0;
  width: 50%;
}
.timeline .timeline-container .timeline-item:nth-child(odd) {
  align-self: flex-end;
  justify-content: flex-start;
  padding-right: 0px;
  padding-left: 2rem;
}
.timeline .timeline-container .timeline-item:nth-child(odd) .timeline-item-content {
  align-items: center;
  text-align: center;
}
.timeline .timeline-container .timeline-item:nth-child(odd) .timeline-item-content::after {
  right: auto;
  left: -7.5px;
}
.timeline .timeline-container .timeline-item:nth-child(odd) .timeline-item-content .tag {
  left: auto;
  right: 5px;
}
.timeline .timeline-container .timeline-item:nth-child(odd) .timeline-item-content .circle {
  right: auto;
  left: -2.6rem;
}
.timeline .timeline-container .timeline-item .timeline-item-content {
  border-radius: 20px;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  max-width: 100%;
  text-align: center;
  position: relative;
}
.timeline .timeline-container .timeline-item .timeline-item-content .tag {
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  padding: 5px;
  position: absolute;
  top: 5px;
  left: 5px;
}
.timeline .timeline-container .timeline-item .timeline-item-content .time {
  font-weight: 700;
}
.timeline .timeline-container .timeline-item .timeline-item-content p {
  font-size: 1.2rem;
  margin: 1rem 0;
  line-height: 1.5rem;
  max-width: 20rem;
  font-weight: 500;
}
.timeline .timeline-container .timeline-item .timeline-item-content a {
  color: #b57aff;
  text-decoration: none;
  font-size: 14px;
}
.timeline .timeline-container .timeline-item .timeline-item-content a:after {
  content: "→";
}
.timeline .timeline-container .timeline-item .timeline-item-content .circle {
  background-color: #fff;
  border: 3px solid #444;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  right: -3.4rem;
  z-index: 20;
  position: absolute;
  top: 50%;
}

@media only screen and (max-width: 400px) {
  .timeline-item-content,
.timeline-item:nth-child(odd).timeline-item-content {
    text-align: center;
    align-items: center;
    width: 380px;
  }
}
.table-container {
  display: flex;
  justify-content: center;
}
.table-container table {
  border-radius: 10px;
  border-collapse: collapse;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-height: 100vh;
  overflow-y: scroll;
}
.table-container table thead {
  background-color: #b4e4c9;
  color: #111;
}
.table-container table thead tr th {
  padding: 0.5rem;
}
.table-container table tbody {
  text-align: center;
}
.table-container table tbody tr td {
  padding: 0.5rem;
}
.table-container table tbody tr:nth-of-type(even) {
  background-color: #efefef;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1rem;
  margin: 2rem 0;
}
.form h1 {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .form h1 {
    font-size: 3rem;
  }
}
.form p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}
.form .heading {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.form .heading div {
  font-size: 1.5rem;
  color: #666;
  padding: 1rem 0;
}
.form .field {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media (max-width: 576px) {
  .form .field {
    align-items: center;
  }
}
.form .field .custom-label-wrapper {
  display: flex;
  padding: 2rem 1rem 1rem 0;
}
.form .field .custom-label-wrapper .label {
  padding: 0 0.5rem 0 0;
}
.form .field .label {
  padding: 2rem 1rem 1rem 0;
}
.form .field .error {
  padding: 1rem 0 1rem 0;
  color: #ff073a;
}
.form .field .control {
  width: 40rem;
}
@media (min-width: 577px) and (max-width: 1449px) {
  .form .field .control {
    width: 30rem;
  }
}
@media (max-width: 576px) {
  .form .field .control {
    width: 80vw;
  }
}
.form .field .control input {
  width: 100%;
  margin: 0;
}
.form .field .control textarea {
  width: 100%;
  height: 8rem;
  margin: 0;
}
.form .submit {
  margin-top: 4rem;
}

@keyframes spainWithoutA {
  0% {
    transform: translateY(-50%) translateX(-50%) rotate(40deg) translateX(-20%);
  }
  25% {
    transform: translateY(-50%) translateX(-50%) skew(15deg, 15deg) rotate(110deg) translateX(-5%);
  }
  50% {
    transform: translateY(-50%) translateX(-50%) rotate(210deg) translateX(-35%);
  }
  75% {
    transform: translateY(-50%) translateX(-50%) skew(-15deg, -15deg) rotate(300deg) translateX(-10%);
  }
  100% {
    transform: translateY(-50%) translateX(-50%) rotate(400deg) translateX(-20%);
  }
}
.blurred-circles {
  z-index: -1;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.blurred-circles .wrapper {
  filter: blur(80px);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.blurred-circles .wrapper div {
  position: absolute;
  border-radius: 100%;
}
.blurred-circles .wrapper div:nth-of-type(1) {
  z-index: -1;
  background-color: #50e3c2;
  opacity: 0.5;
  top: 60%;
  left: 40%;
  width: 700px;
  height: 700px;
  animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite alternate;
}
.blurred-circles .wrapper div:nth-of-type(2) {
  z-index: -2;
  background-color: #7928ca;
  opacity: 0.45;
  top: 50%;
  left: 50%;
  width: 600px;
  height: 600px;
  animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite both;
}
.blurred-circles .wrapper div:nth-of-type(3) {
  z-index: -3;
  background-color: #50e3c2;
  opacity: 0.3;
  top: 40%;
  left: 60%;
  width: 500px;
  height: 500px;
  animation: cubic-bezier(0.1, 0, 0.9, 1) spainWithoutA 11s infinite reverse;
}

.psa {
  display: flex;
  align-items: center;
  text-align: center;
  height: 12rem;
  width: 20rem;
  border-radius: 5px;
  position: absolute;
  top: 10%;
  right: 10%;
  background-color: #50e3c2cc;
}
.psa-confirm {
  display: none;
  position: absolute;
  z-index: 10;
  margin: 0 40% 0 40%;
}
.psa-confirm span {
  display: block;
  width: 70px;
  height: 10px;
  background-color: #000;
}
.psa-confirm span:first-of-type {
  transform: translateY(5px) rotate(45deg);
}
.psa-confirm span:last-of-type {
  transform: translateY(-5px) rotate(-45deg);
}
.psa-content {
  font-size: 1.5rem;
}
.psa:hover {
  cursor: pointer;
}
.psa:hover .psa-confirm {
  display: block;
}
.psa:hover .psa-content {
  z-index: -1;
}

.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.faq-hero {
  padding: 4rem 1rem;
  margin: 2rem 0;
}
.faq-hero .title {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .faq-hero .title {
    font-size: 3rem;
  }
}
.faq-hero p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}
.faq-hero-search {
  padding: 1rem 1rem 1rem 1rem;
  margin: 1rem 0 1rem 0;
}
.faq-hero-search .title {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .faq-hero-search .title {
    font-size: 3rem;
  }
}
.faq-hero-search p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
}
lin .faq-hero-search p-search-box {
  text-align: center;
}
.faq-search-box {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.faq-search-box h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.faq-search-box .input {
  width: 80vw;
  padding: 1rem 1.4rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #eaeaea;
  color: #111;
  font: inherit;
}
@media (min-width: 1450px) {
  .faq-search-box .input {
    width: 50rem;
  }
}
.faq .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  padding: 0 1rem;
}
.faq .container h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3rem;
  padding-top: 1.8rem;
}
.faq .container ul {
  list-style-type: none;
  padding: 0.5rem 0;
}
.faq .container ul li {
  margin: 1rem 0rem 1rem 0rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}
.faq .container ul li p:first-of-type {
  font-size: 1.1rem;
  font-weight: 600;
}
.faq .container ul li ul {
  list-style-type: square;
  margin: 0 3rem;
}
.faq .container ul li blockquote {
  color: grey;
  text-align: center;
  border-left: 10px solid #50e3c2;
  padding: 1rem 0;
}
.faq .container ul li blockquote p,
.faq .container ul li blockquote p:first-of-type {
  margin: 0;
}

.particles {
  width: 100%;
  height: 100%;
  background: url("../assets/intro-background.jpg");
  background-size: cover;
}
.particles:before {
  content: "";
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.home {
  display: flex;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home .hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  height: 90vh;
  margin: 0;
}
.home .hero .hero-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50%;
  padding: 1rem;
  text-align: center;
}
@media (max-width: 576px) {
  .home .hero .hero-body {
    width: 100%;
  }
}
.home .hero .hero-body .title {
  color: #111;
  margin-top: 2rem;
  font-family: "Inter", sans-serif;
  letter-spacing: 2px;
  font-size: 8rem;
  font-weight: 800;
}
@media (max-width: 576px) {
  .home .hero .hero-body .title {
    font-size: 5.5rem;
  }
}
.home .hero .hero-body .subtitle {
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif;
  font-size: 2.5rem;
  margin: 0.75rem;
  font-weight: 600;
  color: #444;
}
.home .hero .hero-body .subtitle a {
  display: flex;
}
.home .hero .hero-body .subtitle img {
  width: 10rem;
  margin-left: 1rem;
}
.home .hero .hero-body .btns-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 576px) {
  .home .hero .hero-body .btns-container {
    flex-direction: column;
  }
}
.home .container h1 {
  font-size: 4rem;
  font-family: "Inter", sans-serif;
  letter-spacing: 2px;
  align-items: center;
}
.home .about {
  color: #111;
  padding-bottom: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 5rem 20% 0 20%;
}
.home .about p {
  font-size: 1.3rem;
}
.home .why-kwoc {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home .why-kwoc h1 {
  text-align: center;
}
.home .why-kwoc-cardlist {
  display: flex;
  justify-content: space-evenly;
  width: 70vw;
  margin: 2rem 0;
}
@media (max-width: 576px) {
  .home .why-kwoc-cardlist {
    flex-direction: column;
    width: 80vw;
  }
}
.home .why-kwoc-card {
  color: #111;
  margin: 1rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.home .why-kwoc-card h2 {
  font-size: 1.5rem;
}
@media (max-width: 600px) {
  .home .why-kwoc .container {
    padding: 30px 10% 5rem;
  }
}
.home .tags {
  text-align: center;
  min-height: 50vh;
  margin-top: 5rem;
}
.home .tags-title {
  margin-bottom: 2rem;
}
.home .tags-list {
  margin: 2rem 0;
}
.home .tags .tag {
  display: inline-block;
  text-decoration: none;
  padding: 0.8rem 1.2rem;
  margin: 1rem;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
}
.home .previous-year {
  min-height: 80vh;
  background-size: cover;
  margin: 0 0 5rem 0;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.home .previous-year h1 {
  font-size: 4rem;
  text-align: center;
}
.home .previous-year-column-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 4rem;
}
@media (max-width: 576px) {
  .home .previous-year-column-wrapper {
    flex-direction: column;
  }
}
.home .previous-year-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  padding: 1.5rem;
  border-radius: 20px;
  width: 20rem;
}
@media (max-width: 768px) {
  .home .stats .container .columns .column {
    padding: 2rem 0;
  }
}

@media (max-width: 400px) {
  .hero .manual-btns {
    flex-direction: column;
    padding: 0 20%;
  }
  .hero .manual-btns .control:not(:last-child) {
    margin-right: 0 !important;
  }
}
* {
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
}

html {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.App .hero h1 {
  font-size: 5rem;
}

h1 {
  margin-top: 3%;
  margin-bottom: -1%;
}

.projects-hero {
  padding: 4rem 1rem 1rem 1rem;
  margin: 4rem 0 1rem 0;
}
.projects-hero .title {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .projects-hero .title {
    font-size: 3rem;
  }
}
.projects-hero p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}
.projects-search-box {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projects-search-box h1 {
  font-size: 4rem;
  margin-bottom: 1.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
}
.projects-search-box .input {
  width: 80vw;
  padding: 1rem 1.4rem;
  border-radius: 5px;
  outline: none;
  border: 1px solid #eaeaea;
  color: #111;
  font: inherit;
}
@media (min-width: 1450px) {
  .projects-search-box .input {
    width: 50rem;
  }
}

.project-card-grid {
  padding: 5rem 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 3rem;
  row-gap: 3rem;
  overflow-x: hidden;
}
@media (max-width: 576px) {
  .project-card-grid {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

.testimonial-hero {
  padding: 4rem 1rem;
  margin: 4rem 0;
}
.testimonial-hero .title {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .testimonial-hero .title {
    font-size: 3rem;
  }
}
.testimonial-hero p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}
.testimonial-list {
  padding: 5rem 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  column-gap: 2rem;
  row-gap: 2rem;
  overflow-x: hidden;
}
@media (max-width: 576px) {
  .testimonial-list {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
.testimonial-box {
  background-color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  border: 1px solid #eaeaea;
}
.testimonial-box img {
  width: 128px;
  height: 128px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0 1.5rem;
}
.testimonial-box a {
  padding: 0.5rem 1.2rem;
  margin-top: 1rem;
}
.testimonial-header {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
.testimonial-header-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.testimonial-header .testimonial-name {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
}
.testimonial-header .testimonial-biotext {
  font-size: 1rem;
  color: #666;
}
.testimonial-desc {
  padding: 1.5rem 1rem;
  color: #333;
}

.dashboard-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 44.42%, #000000 64.48%), url(/dashboard-bg.jpeg);
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #000;
  color: #fff;
  padding: 1rem;
}
.dashboard-container .dashboard a {
  color: rgba(255, 255, 255, 0.7);
}
.dashboard-container .dashboard a:hover {
  color: white;
}
.dashboard-container .dashboard-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 2rem 0;
}
.dashboard-container .dashboard-header h1 {
  font-size: 4rem;
  margin: 0;
}
.dashboard-container .dashboard-header h2 {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 0.5rem 0;
}
.dashboard-container .dashboard-header .stats {
  display: flex;
  flex-direction: row;
}
.dashboard-container .dashboard-header .stats .box {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 0.5rem;
  padding: 1rem 2.5rem;
  text-align: center;
}
.dashboard-container .dashboard-header .stats .box h2 {
  font-size: 4rem;
  margin: 0.5rem 0;
  color: #fff;
}
.dashboard-container .dashboard-header .stats .box p {
  color: rgba(255, 255, 255, 0.7);
}
.dashboard-container .dashboard-header .avatar {
  margin: 0 1rem;
}
.dashboard-container .dashboard-header .avatar img {
  border-radius: 10px;
  max-height: 20rem;
}
.dashboard-container .dashboard-header .avatar-content {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
}
.dashboard-container .dashboard-header .avatar-content h2 {
  margin: 0.5rem 0;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard-header .avatar {
    display: none;
  }
}
@media (max-width: 576px) {
  .dashboard-container .dashboard-header {
    flex-direction: column;
  }
  .dashboard-container .dashboard-header .stats {
    flex-direction: column;
  }
  .dashboard-container .dashboard-header .stats .box {
    width: 100%;
  }
}
.dashboard-container .dashboard .subtitle {
  flex-grow: 1;
}
.dashboard-container .dashboard .subtitle h1 {
  font-size: 2.5rem;
  margin: 2rem 0 1rem 0;
}
.dashboard-container .dashboard .subtitle .languages span {
  background: rgba(255, 255, 255, 0.1);
  margin: 0 1rem 0 0;
  padding: 0.5rem 0.8rem;
  border-radius: 10%;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard .subtitle .languages span {
    border-radius: 2%;
    margin: 1rem;
    display: block;
  }
}
.dashboard-container .dashboard .feedback {
  font-size: 1.5rem;
}
.dashboard-container .dashboard .feedback p {
  color: rgba(255, 255, 255, 0.7);
  max-width: 50vw;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard .feedback p {
    max-width: 100%;
  }
}
.dashboard-container .dashboard .feedback a {
  color: #5683ff;
}
.dashboard-container .dashboard-table {
  max-width: 50vw;
}
.dashboard-container .dashboard-table thead th {
  font-size: 1.5rem;
}
.dashboard-container .dashboard-table tbody a {
  margin: 0.5rem;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard-table {
    display: none;
  }
}
.dashboard-container .dashboard-table-message {
  display: none;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard-table-message {
    display: block;
  }
}
.dashboard-container .dashboard .resource-table table tbody td {
  margin: 0 1rem 0 0;
  font-size: 1.2rem;
}
.dashboard-container .dashboard .resource-table table tbody td img {
  height: 100px;
  border-radius: 10px;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard .resource-table table tbody td img {
    height: 70px;
  }
}
.dashboard-container .dashboard .project-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 1rem 0 0;
  max-width: 50vw;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard .project-cards {
    max-width: 100%;
  }
}
.dashboard-container .dashboard .project-cards .card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  max-width: 350px;
  max-height: 200px;
}
@media (max-width: 576px) {
  .dashboard-container .dashboard .project-cards .card-container {
    width: 100%;
  }
}
.dashboard-container .dashboard .project-cards .card-container p,
.dashboard-container .dashboard .project-cards .card-container h1 {
  padding: 0px;
  margin: 0px;
}
.dashboard-container .dashboard .project-cards .card-container .info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-container .dashboard .project-cards .card-container .info img {
  height: 40px;
  width: 40px;
  margin: 0.5rem;
  border-radius: 2px;
}
.dashboard-container .dashboard .project-cards .card-container .info p {
  font-size: 1.5rem;
}
.dashboard-container .dashboard .project-cards .card-container .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dashboard-container .dashboard .project-cards .card-container .buttons a {
  margin: 0.5rem;
}
.dashboard-container .students {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 50vw;
}
@media (max-width: 576px) {
  .dashboard-container .students {
    max-width: 100%;
  }
}
.dashboard-container .students .student {
  margin: 1rem;
}
.dashboard-container .students .student .header img {
  height: 8rem;
  border-radius: 10px;
}
.dashboard-container .add-project-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 1rem;
  padding: 1rem;
  width: fit-content;
  max-width: 350px;
  max-height: 200px;
}
.dashboard-container .add-project-link .plus-sign {
  font-size: 3rem;
  margin: 0;
}
.dashboard-container .pull-reqs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 50vw;
}
@media (max-width: 576px) {
  .dashboard-container .pull-reqs {
    max-width: 100%;
  }
}
.dashboard-container .pull-reqs .pull-req {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  margin: 0.2rem;
  padding: 0.5rem;
}
@media (max-width: 576px) {
  .dashboard-container .pull-reqs .pull-req {
    font-size: 3vw;
  }
}
.dashboard-container .pull-reqs .pull-req .link {
  display: inline;
  margin-right: 0.2rem;
  margin-left: 0.5rem;
}
.dashboard-container .pull-reqs .pull-req .link a {
  color: #5683ff;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stats .title {
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 1.2;
  text-align: center;
  color: #000;
}
@media (max-width: 576px) {
  .stats .title {
    font-size: 3rem;
  }
}
.stats p {
  color: #666;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: center;
}